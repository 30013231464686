import { FC } from 'react';
import s from './banner.module.scss';
import cn from 'classnames';
import { Button } from '@components/UIKit/Button/Button';
import { Content } from '@specs/views';
import { OptimizedImage } from '@components/OptimizedImage/OptimizedImage';
interface BannerProps extends Content.promoWithDrops {
  isVertical: boolean;
}
export const Banner: FC<BannerProps> = ({
  isVertical,
  foregroundImageId,
  backgroundImageId,
  title,
  color,
  buttonTitle,
  buttonUri,
  description,
  subtitle
}) => <div className={cn(s.banner, isVertical && s.banner_vertical)} style={{
  backgroundColor: color ?? '#CBD0DC'
}} data-sentry-component="Banner" data-sentry-source-file="Banner.tsx">
        <OptimizedImage path={'/promo/background'} cdnId={backgroundImageId} alt={'banner bg image'} boxClassName={s.banner__bg} fill style={{
    objectFit: 'cover'
  }} data-sentry-element="OptimizedImage" data-sentry-source-file="Banner.tsx" />
        <div className={s.banner__box}>
            <div className={s.banner__images}>
                <OptimizedImage boxClassName={s.banner__image} path={'/promo/foreground'} cdnId={foregroundImageId} alt={'banner image'} width={280} height={280} style={{
        objectFit: 'contain'
      }} data-sentry-element="OptimizedImage" data-sentry-source-file="Banner.tsx" />
            </div>
            <div className={s.banner__content}>
                <div className={s.banner__main}>
                    {title.trim() && <div className={s.banner__title}>{title}</div>}
                    {subtitle.trim() && <div className={s.banner__subtitle}>{subtitle}</div>}
                    {description.trim() && <div className={s.banner__text}>{description}</div>}
                </div>
                {buttonTitle && buttonUri && <Button id='promo_banner_button' className={s.banner__button} size={'medium'} color='Black' onClick={() => {
        window.open(buttonUri, '_blank', 'noreferrer');
      }}>
                        {buttonTitle}
                    </Button>}
            </div>
        </div>
    </div>;