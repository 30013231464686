import React, { useEffect, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import { gtagEventTrigger } from '@specs/helpers';
import { Input, InputProps } from '@components/UIKit/Input/Input';
import { PageQueries, replaceQuery } from '@specs/navigation';
import { useRouter } from 'next/router';
interface SearchProps extends Omit<InputProps, 'leftIcon' | 'onPrint' | 'value'> {
  minDebouncedLength?: number;
}
const SearchMemo = ({
  minDebouncedLength = 1,
  name,
  ...props
}: SearchProps) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchInput = useDebounce(inputValue);
  const onPrint = (value: string) => {
    gtagEventTrigger({
      action: 'search',
      category: 'action'
    });
    setInputValue(value);
  };
  const searchHandler = async (value: PageQueries['query']) => {
    await replaceQuery(router, {
      query: value
    });
  };
  useEffect(() => {
    if (debouncedSearchInput.trim() === '') {
      searchHandler(undefined);
    } else if (debouncedSearchInput.trim().length > minDebouncedLength) {
      searchHandler(debouncedSearchInput);
    }
  }, [debouncedSearchInput]);
  return <Input leftIcon={{
    name: 'search',
    color: 'var(--Hydrargyrum)'
  }} onPrint={onPrint} name={`search_${name}`} value={inputValue} {...props} data-sentry-element="Input" data-sentry-component="SearchMemo" data-sentry-source-file="Search.tsx" />;
};
export const Search = React.memo(SearchMemo);