import React, { type FC, type InputHTMLAttributes, useEffect, useState } from 'react';
import s from './input.module.scss';
import cn from 'classnames';
import { SpriteIcon, SpriteIconProps } from '@components/Sprites/SpriteIcon';
import { ErrorRow } from '@components/UIKit/ErrorRow/ErrorRow';
export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value: string | number;
  onPrint: (value: string) => void;
  boxClassName?: string;
  description?: {
    text: React.ReactNode;
    error: boolean;
  };
  leftIcon?: SpriteIconProps;
  label?: React.ReactNode;
}
export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
const InputMemo: FC<InputProps> = ({
  onPrint,
  boxClassName,
  className,
  description,
  leftIcon,
  value,
  onChange,
  label,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = useState(!value);
  useEffect(() => {
    setIsEmpty(!value);
  }, [value]);
  return <div className={cn(s.inputBox, boxClassName)} data-sentry-component="InputMemo" data-sentry-source-file="Input.tsx">
            {label && <div className={s.inputBox__label}>
                    {label}
                </div>}
            <label className={cn(s.inputBox__row, !!leftIcon && s.inputBox__row_withLeftIcon, props?.disabled && s.inputBox__row_disabled, !!description?.error && s.inputBox__row_error, !isEmpty && s.inputBox__row_filled)}>
                {leftIcon && <SpriteIcon className={s.inputBox__leftIcon} color={!isEmpty ? '--Goshawk-Grey' : undefined} {...leftIcon} />}
                <input className={cn(s.inputBox__input, '_ellipsis', className)} onKeyDown={props.type === 'number' ? blockInvalidChar : undefined} onChange={e => {
        onChange?.(e);
        onPrint(e.target.value);
      }} value={value} {...props} />
                {!isEmpty && <SpriteIcon className={s.inputBox__close} name={'close'} onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onPrint('');
        setIsEmpty(true);
      }} />}
            </label>
            {typeof description !== 'undefined' && <div className={s.inputBox__description}>
                    {description.error ? <ErrorRow>{description.text}</ErrorRow> : description.text}
                </div>}
        </div>;
};
export const Input = React.memo(InputMemo);