import React, { useLayoutEffect, useState } from 'react';
import s from './grid.module.scss';
import cn from 'classnames';
import { getRandomIntInclusive } from '@helpers/helpers';
import { Content } from '@specs/views';
import { Banner } from '@components/Cards/Banner/Banner';
interface IState {
  isVertical: boolean;
  style: React.CSSProperties | undefined;
}
interface PageBannerProps {
  data: Content.promoWithDrops[];
}
export const PageBanner: React.FC<PageBannerProps> = ({
  data
}) => {
  const [{
    isVertical,
    style
  }, setState] = useState<IState>({
    isVertical: false,
    style: undefined
  });
  useLayoutEffect(() => {
    const isVertical = Boolean(getRandomIntInclusive(0, 1));
    const isEnd = Boolean(getRandomIntInclusive(0, 1));
    const column = isVertical ? isEnd ? {
      gridColumn: `span 1 / ${getRandomIntInclusive(-1, -2)}`
    } : {
      gridColumn: ` ${getRandomIntInclusive(1, 2)}/span 1`
    } : isEnd ? {
      gridColumn: `span 2 / ${getRandomIntInclusive(-1, -2)}`
    } : {
      gridColumn: ` ${getRandomIntInclusive(1, 2)}/span 2`
    };
    const row = isVertical ? {
      gridRow: `${getRandomIntInclusive(1, 2)} / span 2`
    } : {
      gridRow: `${getRandomIntInclusive(1, 3)} / span 1`
    };
    const style = {
      ...column,
      ...row
    };
    setState({
      isVertical,
      style
    });
  }, []);
  if (!style) return null;
  return <div className={cn(s.grid__banner, isVertical ? s.grid__banner_vertical : s.grid__banner_horizontal)} style={style} data-sentry-component="PageBanner" data-sentry-source-file="PageBanner.tsx">
            <Banner isVertical={isVertical} {...data[getRandomIntInclusive(0, data.length - 1)]} data-sentry-element="Banner" data-sentry-source-file="PageBanner.tsx" />
        </div>;
};