import React from 'react';
import s from './grid.module.scss';
import { api } from '@api';
import { PageBanner } from './PageBanner';
interface PageProps {
  children: React.ReactNode;
  showBanners?: boolean;
  limit?: number;
  itemsLength?: number;
  skipFirstBanner?: boolean;
}
const PageMemo: React.FC<PageProps> = ({
  showBanners,
  children,
  skipFirstBanner
}) => {
  const {
    data,
    status
  } = api.ContentManager.getPromo.useQuery(undefined, {
    queryKey: ['ContentManager.getPromo', undefined],
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
    enabled: showBanners
  });
  if (showBanners && status !== 'success') return null;
  return <div className={s.grid__page} data-sentry-component="PageMemo" data-sentry-source-file="Page.tsx">
            {showBanners && !!data?.length && !skipFirstBanner && <PageBanner data={data} />}
            {children}
        </div>;
};
export const Page = React.memo(PageMemo);