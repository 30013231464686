import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import s from './pagination.module.scss';
import { ChevronLeftIcon } from './ChevronLeftIcon';
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
}
export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  className
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  const renderPaginationItems = useMemo(() => {
    const items: (number | string)[] = [];
    const maxVisibleItems = isMobile ? 7 : 9;
    if (totalPages <= maxVisibleItems) {
      return Array.from({
        length: totalPages
      }, (_, i) => i + 1);
    }
    items.push(1);
    if (isMobile) {
      // Mobile logic (up to 7 items)
      if (currentPage > 3) {
        items.push('...');
      }
      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        if (!items.includes(i)) {
          items.push(i);
        }
      }
    } else {
      if (currentPage > 4) {
        items.push('...');
      }
      for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
        if (!items.includes(i)) {
          items.push(i);
        }
      }
    }
    if (isMobile ? currentPage < totalPages - 2 : currentPage < totalPages - 3) {
      items.push('...');
    }
    if (totalPages > 1) {
      items.push(totalPages);
    }
    return items;
  }, [currentPage, totalPages, isMobile]);
  if (totalPages <= 1) return null;
  return <div className={cn(s.pagination, className)} data-sentry-component="Pagination" data-sentry-source-file="Pagination.tsx">
            <button className={cn(s.arrow)} onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                <ChevronLeftIcon data-sentry-element="ChevronLeftIcon" data-sentry-source-file="Pagination.tsx" />
            </button>

            {renderPaginationItems.map((item, index) => item === '...' ? <div key={index} className={s.ellipsis}>
                    {item}
                </div> : <button key={index} className={`${s.pageItem} ${item === currentPage ? s.active : ''}`} onClick={() => onPageChange(item as number)}>
                    {item}
                </button>)}

            <button className={cn(s.arrow)} onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <ChevronLeftIcon className={cn(s.rotate)} data-sentry-element="ChevronLeftIcon" data-sentry-source-file="Pagination.tsx" />
            </button>
        </div>;
};