import React, { FC, useEffect, useMemo } from 'react';
import s from './postCard.module.scss';
import { PostView } from '@data/post/PostManagerSpec';
import moment from 'moment';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import { Avatar } from '@components/UIKit/Avatar/Avatar';
import { useGetPublicProfile } from '@hooks/TRPC_Hooks/useGetPublicProfile';
import { TextEditor } from '@components/UIKit/TextToolbar';
import { PostCardFooter } from './PostCardFooter';
import { getPostCardName, isPostPersonal, PreviewService, truncateTipTapText } from '@services/posts/helpers';
import { JSONContent } from '@tiptap/react';
import { ImgS3 } from '@components/UIKit/ImgS3/ImgS3';
import { PostCardEdit } from './PostCardEdit';
import { useRouter } from 'next/router';
import { getRtoystUrl, LinksEnum } from '@specs/navigation';
import { TRUNCATE_PREVIEW_MAX_CHARS_DESKTOP, TRUNCATE_PREVIEW_MAX_CHARS_MOBILE } from '@services/posts/constants';
import { useProfile } from '@hooks/TRPC_Hooks/useProfile';
import Link from 'next/link';
import { useGetPost } from '@services/posts/hooks/useGetPost';
import { api } from '@api';
import { useTranslation } from '@hooks/useTranslation';
type PostCardProps = {
  initialPost: PostView;
  isMobile: boolean;
};
const formatCardDate = (date?: Date) => {
  if (!date) return '';
  return moment(date).fromNow();
};
export const PostCardPreview: FC<PostCardProps> = ({
  initialPost,
  isMobile
}) => {
  const utils = api.useUtils();
  const router = useRouter();
  const {
    data: myProfile
  } = useProfile();
  const {
    translate: tr
  } = useTranslation();
  const {
    data: personalPost
  } = useGetPost({
    post_id: initialPost.id
  }, {
    initialData: initialPost
  });
  const post = personalPost ?? initialPost;
  const {
    id,
    content_new,
    publish_date,
    post_reactions,
    type,
    creator
  } = post;
  const isPersonal = isPostPersonal(myProfile?.id, post.author_id);
  const {
    headlessContent,
    imageNode,
    headingText
  } = PreviewService.getPreviewContent((content_new ?? []) as JSONContent[]);
  const {
    data: profile,
    isLoading: profileLoading
  } = useGetPublicProfile({
    shortId: creator?.shortid ?? ''
  });
  const MAX_CHARS = useMemo(() => {
    if (isMobile) return TRUNCATE_PREVIEW_MAX_CHARS_MOBILE;
    return TRUNCATE_PREVIEW_MAX_CHARS_DESKTOP;
  }, [isMobile]);
  const truncatedContentForPreview = useMemo(() => truncateTipTapText(headlessContent, MAX_CHARS), [headlessContent, MAX_CHARS]);
  const {
    view
  } = TextEditor({
    content: truncatedContentForPreview
  });
  const goToPost = async () => {
    await router.push({
      pathname: LinksEnum.POST,
      query: {
        slug: post.id
      }
    });
  };
  useEffect(() => {
    const cachedData = utils.PostsManager.getPost.getData({
      post_id: initialPost.id
    });
    if (!cachedData) {
      utils.PostsManager.getPost.setData({
        post_id: initialPost.id
      }, initialPost);
    }
  }, []);
  const link = getRtoystUrl(LinksEnum.POST, id);
  return <div className={s.postCardList} data-sentry-component="PostCardPreview" data-sentry-source-file="PostCardPreview.tsx">
            <div className={s.postCardList__header}>
                <div className={s.postCardList__left}>
                    {type === 'NEWS' ? <Link href={{
          pathname: LinksEnum.USER,
          query: {
            slug: creator?.shortid
          }
        }}>
                            <SpriteIcon colored width={52} height={52} name={'avatar_platform'} />
                        </Link> : <Avatar className={s.postCardList__left__avatar} srcSet={[{
          width: 52,
          height: 52,
          minMedia: 0
        }]} isLoading={profileLoading} profile={profile} />}
                    <div className={s.postCardList__user}>
                        <div className={s.postCardList__name}>
                            {getPostCardName(post)}
                        </div>
                        <span className={s.postCardList__date}>
                            {formatCardDate(publish_date)}
                        </span>
                    </div>
                </div>

                <div className={s.postCardList__share}>
                    <PostCardEdit postId={post.id} link={link} isPersonal={isPersonal} shortId={creator.shortid} data-sentry-element="PostCardEdit" data-sentry-source-file="PostCardPreview.tsx" />
                </div>
            </div>

            <ImgS3 boxClassname={s.postCardList__media} src={imageNode?.attrs?.src} sizes="(max-width: 640px) 100vw, 1533px" fill loading='lazy' onClick={goToPost} data-drag-handle style={{
      objectFit: 'cover'
    }} alt="Uploaded post" title={post.title ? tr({
      id: 'post.image.title'
    }, {
      title: post.title
    }) : tr({
      id: 'post.image.title.default'
    })} folder="posts" data-sentry-element="ImgS3" data-sentry-source-file="PostCardPreview.tsx" />

            <div className={s.postCardList__info}>
                <Link href={getRtoystUrl(LinksEnum.POST, post.id)} data-sentry-element="Link" data-sentry-source-file="PostCardPreview.tsx">
                    <h2>
                        {headingText}
                    </h2>
                </Link>

                <Link href={getRtoystUrl(LinksEnum.POST, post.id)} data-sentry-element="Link" data-sentry-source-file="PostCardPreview.tsx">
                    {view}
                </Link>
            </div>

            <PostCardFooter link={link} postId={post.id} postReactions={post_reactions} data-sentry-element="PostCardFooter" data-sentry-source-file="PostCardPreview.tsx" />
        </div>;
};