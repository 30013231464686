import { useEffect, useState } from 'react'

const useDebounce = <T = string>(value: T, delay: number = 300) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => clearTimeout(handler)
    }, [value, delay])
    return debouncedValue
}

export default useDebounce
