import React, { FC } from 'react';
import s from './emptyList.module.scss';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import classNames from 'classnames';
import { useTranslation } from '@hooks/useTranslation';
type EmptyListProps = {
  className?: string;
};
export const EmptyList: FC<EmptyListProps> = ({
  className
}) => {
  const {
    translate: tr
  } = useTranslation();
  return <div className={classNames(s.emptyList, className)} data-sentry-component="EmptyList" data-sentry-source-file="EmptyList.tsx">
            <SpriteIcon className={s.emptyList__icon} colored name={'empty_cart'} data-sentry-element="SpriteIcon" data-sentry-source-file="EmptyList.tsx" />

            <div className={s.emptyList__body}>
                <p className={s.emptyList__title}>{tr({
          id: 'empty.list.title'
        })}</p>
                <p className={s.emptyList__text}>{tr({
          id: 'empty.list.text'
        })}</p>
            </div>
        </div>;
};