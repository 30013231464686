import { api, ReactQueryOptions, RouterInputs, RouterOutputs } from '@api';
import { staleTimeIndex } from '@specs/constants';
import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCInfiniteQueryOptions } from '@trpc/react-query/shared';
type inputType = RouterInputs['PostsManager']['getAuthorFeed'];
type outputType = RouterOutputs['PostsManager']['getAuthorFeed'];
type optionsType = UseTRPCInfiniteQueryOptions<'PostsManager.getAuthorFeed', inputType, outputType, TRPCClientErrorLike<any>>;
export const useGetInfinityPostFeed = (input: inputType, options?: optionsType) => api.PostsManager.getAuthorFeed.useInfiniteQuery(input, {
  queryKey: ['PostsManager.getAuthorFeed', input],
  getNextPageParam: firstPage => firstPage.nextCursor,
  initialCursor: undefined,
  staleTime: 1000 * staleTimeIndex * 60,
  // 1 minute
  ...options
});
type inputPublicType = RouterInputs['PostsManager']['getPublicFeed'];
type outputPublicType = RouterOutputs['PostsManager']['getPublicFeed'];
type optionsPublicType = UseTRPCInfiniteQueryOptions<'PostsManager.getPublicFeed', inputPublicType, outputPublicType, TRPCClientErrorLike<any>>;
export const useGetInfinityPostPublicFeed = (input: inputPublicType, options?: optionsPublicType) => api.PostsManager.getPublicFeed.useInfiniteQuery(input, {
  queryKey: ['PostsManager.getPublicFeed', input],
  getNextPageParam: firstPage => firstPage.nextCursor,
  initialCursor: undefined,
  staleTime: 1000 * staleTimeIndex * 60,
  // 1 minute
  ...options
});
type queryOptionsType = ReactQueryOptions['PostsManager']['getAuthorFeed'];
export const useGetPostsFeed = (input: inputType, options?: queryOptionsType) => api.PostsManager.getAuthorFeed.useQuery(input, {
  queryKey: ['PostsManager.getAuthorFeed', input],
  staleTime: 1000 * staleTimeIndex * 60,
  // 1 minute
  ...options
});
type queryOptionsPublicType = ReactQueryOptions['PostsManager']['getPublicFeed'];
export const useGetPostsPublicFeed = (input: inputPublicType, options?: queryOptionsPublicType) => api.PostsManager.getPublicFeed.useQuery(input, {
  queryKey: ['PostsManager.getPublicFeed', input],
  staleTime: 1000 * staleTimeIndex * 60,
  // 1 minute
  ...options
});