import React, { FC } from 'react';
import { SpriteIcon } from '@components/Sprites/SpriteIcon';
import s from './errorRow.module.scss';
interface Props {
  children: React.ReactNode;
}
export const ErrorRow: FC<Props> = ({
  children
}) => {
  return <div className={s.errorRow} data-sentry-component="ErrorRow" data-sentry-source-file="ErrorRow.tsx">
            <SpriteIcon name={'info'} width={18} height={18} color={'--Mandarin-Red'} data-sentry-element="SpriteIcon" data-sentry-source-file="ErrorRow.tsx" />
            {children}
        </div>;
};