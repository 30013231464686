import { useEffect, useState } from 'react'
import { HEADER_ID } from '@specs/constants'

export const useHeaderHeight = () => {
    const [headerHeight, setHeaderHeight] = useState(0)

    useEffect(() => {
        const header = document.getElementById(HEADER_ID)
        if (!header) return
        let timeout

        const setValue = () => {
            const value = header.offsetHeight
            setHeaderHeight(value)
        }

        const resizeHandler = () => {
            clearTimeout(timeout)
            timeout = setTimeout(setValue, 300)
        }

        window.addEventListener('resize', resizeHandler)

        setValue()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    return headerHeight
}
