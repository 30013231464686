import React from 'react';
import cn from 'classnames';
import { LogoLoader } from '@components/UIKit/LogoLoader/LogoLoader';
import { EmptyList } from '@components/UIKit/EmptyList/EmptyList';
import { Pagination } from '@components/UIKit/Pagination/Pagination';
import { Page } from './Page';
import s from './grid.module.scss';
interface PaginatedGridProps<T> {
  data?: {
    items: T[];
    totalPages: number;
  };
  children: (props: T) => React.ReactNode;
  currentPage: number;
  onPageChange: (page: number) => void;
  status: 'error' | 'loading' | 'success';
  gridVariant: 'toys' | 'toys_vertical' | 'sets' | 'artists' | 'qrCodes' | 'feed';
  EmptyComponent?: () => JSX.Element;
  className?: string;
  showBanners?: boolean;
  skipFirstBanner?: boolean;
}
export const PaginatedGrid = <T extends Record<'id', number>,>({
  data,
  children,
  currentPage,
  onPageChange,
  status,
  gridVariant,
  EmptyComponent,
  className,
  showBanners = false,
  skipFirstBanner = false
}: PaginatedGridProps<T>) => {
  if (status === 'loading') return <LogoLoader />;
  if (status === 'success' && !data?.items.length) {
    return EmptyComponent ? <EmptyComponent /> : <EmptyList />;
  }
  return <div className={cn(s.gridWrapper, className)} data-sentry-component="PaginatedGrid" data-sentry-source-file="PaginatedGrid.tsx">
            <div className={s.content}>
                <div className={cn(s.grid, gridVariant === 'toys_vertical' && s.grid_toysVertical, gridVariant === 'artists' && s.grid_artists, gridVariant === 'qrCodes' && s.grid_qrCodes, gridVariant === 'feed' && s.grid_feed)}>
                    <Page showBanners={showBanners} skipFirstBanner={skipFirstBanner} data-sentry-element="Page" data-sentry-source-file="PaginatedGrid.tsx">
                        {data?.items.map(children)}
                    </Page>
                </div>
            </div>

            {/* {data?.totalPages && data.totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={data.totalPages}
                    onPageChange={onPageChange}
                />
             )} */}

            {data?.totalPages && data.totalPages > 1 ? <Pagination currentPage={currentPage} totalPages={data.totalPages} onPageChange={onPageChange} /> : null}
        </div>;
};